import { EntryShows } from "../components/types";
import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import { Seo } from "../components/seo";

export default function Shows({ data }: { data: EntryShows }) {
  const posts = data.allWpShow.edges;
  return (
    <div className="container">
      <Layout>
        <div>
          {posts.map((post) => {
            return (
              <div key={post.node.id}>
                <Link to={post.node.slug} activeClassName="active" partiallyActive={true}>
                  <h1>{post.node.title}</h1>
                  {post.node.cpt.content ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: post.node.cpt.content,
                      }}
                    />
                  ) : null}
                </Link>
              </div>
            );
          })}
        </div>
      </Layout>
    </div>
  );
}

export const Head = () => <Seo title="FOR – Shows" />;

export const pageQuery = graphql`
  query {
    allWpShow(sort: { order: DESC, fields: date }) {
      edges {
        node {
          id
          title
          slug
          cpt {
            content
            documentation
            images {
              caption
              image {
                id
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
