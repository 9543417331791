import React from "react";
import { Link } from "gatsby";

import styled from "styled-components";
import * as styles from "../components/variables";

export const Nav = styled.nav`
  display: block;
  ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    justify-content: flex-end;
    li {
      margin-right: 2rem;
      margin-top: 1rem;
      @media (max-width: ${styles.breakpointMobile}) {
        margin-right: 0.5rem;
      }
      a {
        &.active {
          font-family: ABC Favorite Lining, serif;
          font-feature-settings: "ss09" 1;
        }
      }
      &:last-child {
        margin-right: 3em;
        @media (max-width: ${styles.breakpointMobile}) {
          margin-right: 0rem;
        }
      }
    }
  }
`;

const Menu = ({ menu }) => (
  <Nav>
    <ul>
      {menu.map((item) => (
        <li key={item.name}>
          <Link
            to={item.link}
            getProps={({ isPartiallyCurrent }) =>
              isPartiallyCurrent ? { className: "active" } : null
            }
          >
            {item.name}
          </Link>
        </li>
      ))}
    </ul>
  </Nav>
);
export default Menu;
