import { LayoutProps } from "./types";
import React from "react";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import Header from "./header";
import { createGlobalStyle } from "styled-components";
import styled from "styled-components";
import * as styles from "../components/variables";
import abcFavorite from "../fonts/ABCFavorit-Regular.woff";
import abcFavoriteLining from "../fonts/ABCFavoritLining-Regular.woff"

const GlobalStyle = createGlobalStyle`
@font-face { 
  font-family: "ABC Favorite Lining";
  src: url(${abcFavoriteLining}) format("woff");
}
@font-face { 
  font-family: "ABC Favorite";
  src: url(${abcFavorite}) format("woff");
}
html {
        font-size: 100%;
        overflow-x: hidden;
        padding:0;
        margin:0;
    }
    body {
        background: black; 
        color: white;
        font-family: ABC Favorite, serif;
        font-dsiplay: fallback;
        padding: 2em;
        font-size: 1.1em;
        p {
          a {
            font-family: ABC Favorite Lining, serif; 
            transition: 0.3s;
            &:hover {
              color: yellow;
              transition: 0.3s;
            }
          }
        }
        @media (max-width: ${styles.breakpointMobile}) {
          padding: 0.5em;
          font-size: 1em;
        }
    }
    h1, h2, h3 {
      font-family: ABC Favorite Lining, serif;
      font-feature-settings: "ss09" 1;
      margin: 0;
      padding: 0;
      display: inline;
      font-size: 1.1em;
      font-weight: normal;
      --ttStylisticSet: normal;
      white-space: nowrap;
      @media (max-width: ${styles.breakpointMobile}) {
        white-space: pre-wrap;
      }
    }
    img.customLogo {
      width: 120px; 
      height: auto;
    }
    p {
      &:first-of-type {  margin-top: 0.3em; }
      &:last-of-type { margin-bottom: 2em; }
    }
    a { 
        color: white;
        text-decoration: none;
        transition: all 0.2s ease-in-out;
        font-family: ABC Favorite, serif;
        &:hover {
            font-family: ABC Favorite Lining, serif;
            p {
              font-family: ABC Favorite, serif;
            }
            color: yellow;
            transition: all 0.2s ease-in-out;
        }
    }
`;

const Container = styled.div`
  max-width: 55vw;
  margin-left: 7.5em;
  margin-top: 2.5em;
  &.home {
    max-width: 66vw;
  }
  @media (max-width: ${styles.breakpointMobile}) {
    margin-left: 0;
    max-width: 100vw;
  }
`;



const Layout = ({ location, children }: LayoutProps) => {
  const { title, menuLinks } = useSiteMetadata();
  return (
    <>
      <GlobalStyle />
      <div>
        <Header siteTitle={title} menuItems={menuLinks} />
          <Container className={location?.pathname === '/' ? 'home' : 'else'}>{children}</Container>
      </div>
    </>
  );
};
export default Layout;
