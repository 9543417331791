import { SeoProps} from "./types"
import React from "react";
import { useSiteMetadata } from "../hooks/use-site-metadata"
import PropTypes from "prop-types"

export const Seo = ({ title, description, pathname, children } : SeoProps) => {
  const { title: defaultTitle, description: defaultDescription, siteUrl } = useSiteMetadata()
  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname || ``}`,
    children: null,
  }
  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
    </>
  )
}

Seo.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``,
}
Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo