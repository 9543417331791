import { HeaderProps } from "./types";
import React from "react";
import { Link } from "gatsby";
import Menu from "./menu";
import styled from "styled-components";
import * as styles from "../components/variables";
import logo from "../images/logo.svg";

export const HeaderStyle = styled.header`
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: fixed;
  width: 100%;
  top: 1em;
  left: 1em;
  align-items: center;
  @media (max-width: ${styles.breakpointMobile}) {
    position: relative;
    left: 0;
  }
`;

export const LogoLink = styled(Link)`
  img {
    width: 100px;
    height: auto%;
    @media (max-width: ${styles.breakpointMobile}) {
      width: 75px;
    }
  }
`;


const Header = ({ menuItems}: HeaderProps) => {
  return (
    <>
      <HeaderStyle>
        <div>
            <LogoLink to="/">
              <img src={logo} alt="logo for" width={100} />
            </LogoLink>
        </div>
        <Menu menu={menuItems} />
      </HeaderStyle>
    </>
  );
};

export default Header;
